#footer {
    @apply mt-16 bg-gradient-to-b from-slate-950 to-slate-900
}

#footer a {
    @apply text-inherit no-underline hover:text-green-400;
}

#footer .title {
    @apply mb-2 text-white text-2xl md:text-xl xl:text-lg tracking-wide font-bold font-semibold
}

#footer p {
    @apply leading-loose
}