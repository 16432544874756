/*
 * Responsive header inspired by:
 * https://codepen.io/nguyenanhtuan/pen/LYMygWv
 */

header {
    min-height: var(--header-height);
    @apply fixed flex top-0 w-full z-50 bg-white select-none justify-between text-sm
}

header a {
    @apply text-inherit no-underline hover:no-underline
}

header.scrolled {
    @apply shadow-[0_8px_24px_-12px_rgba(0,0,0,0.20)]
}

header nav {
    @apply flex max-w-[85rem] w-full px-4 items-center justify-between
}

header ul.dropdown-menu {
    @apply py-2 px-2 text-sm
}

header ul.dropdown-menu > li {
    @apply flex
}

header ul.dropdown-menu > li + li {
    @apply mt-0
}

header ul.dropdown-menu > li > a {
    @apply pl-4 pr-8 py-4 text-inherit hover:text-neutral-950 w-full text-nowrap whitespace-nowrap hover:bg-neutral-100 rounded-md
}

/* animation menu hamburger */
.menu-btn {
    display: inline-block;
    content: '';
    background: transparent url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='currentColor' stroke='none'><path d='M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z'/></svg>") no-repeat;
    width: 24px;
    height: 24px;
    @apply relative flex cursor-pointer
}

@media (min-width: 768px) {
    .menu-btn {
        @apply hidden
    }
}

.menu-btn.open {
    background: transparent url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='currentColor' stroke='none'><path d='m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z'/></svg>") no-repeat;
}

.expand-btn:after {
    display: inline-block;
    content: '';
    background: transparent url("data:image/svg+xml; utf8, <svg class='ms-1 flex-shrink-0 size-4' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='m6 9 6 6 6-6'></path></svg>") no-repeat;
    width: 16px;
    height: 16px;
    opacity: 0.75;
    transition: 0.25s;
    transform: rotate(0deg);
    margin-left: 4px;
}
.expand-btn.open:after {
    transform: rotate(180deg);
}

.menu-items {
    @apply flex self-stretch items-center
}
.menu-items::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    background-color: #fff;
}
.menu-items::-webkit-scrollbar {
    @apply w-[6px] bg-white
}
.menu-items::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: #fff;
}
@media (max-width: 767px) {
    .menu-items {
        @apply fixed block py-2 pl-2 w-80 left-0 h-full bg-white overflow-y-auto text-base;
        /* left: calc(-100vh - 20rem); */
        left: -100vh;
        scrollbar-gutter: stable;
        transition: left 0.3s ease-out;
        top: var(--header-height);
        height: calc(100vh - 80px);
    }
    .menu-items.open {
        left: 0;
    }
}
.menu-items > li {
    @apply block sm:flex sm:items-center sm:self-stretch
}
.menu-items > li > a {
    @apply flex items-center justify-between sm:self-stretch
}
.menu-items > li > .menu-item {
    @apply flex px-3
}
@media (max-width: 767px) {
    .menu-items > li > .menu-item {
        padding: 1rem 1rem;
    }
}
@media (max-width: 767px) {
    .menu-items li a {
        padding: 10px 1rem;
    }
}
.dropdown {
    position: relative;
}
@media (min-width: 768px) {
    .dropdown:hover > .dropdown-menu {
        opacity: 1;
        visibility: visible;
    }
    .dropdown:hover > .expand-btn:after {
        transform: rotate(180deg);
    }
}
@media (min-width: 768px) {
    .dropdown .dropdown-right .expand-btn:after, .dropdown .dropdown-left .expand-btn:after {
        right: 0.625rem;
    }
}
.dropdown-menu {
    @apply w-fit text-nowrap whitespace-nowrap
}

@media (min-width: 768px) {
    .dropdown-menu {
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease;
        top: var(--header-height);
        @apply absolute z-10 bg-white rounded-b rounded-b-lg py-4 shadow-[0_24px_24px_0_rgba(0,0,0,0.20)] right-0 /* inset-x-0 */
    }
}
@media (max-width: 767px) {
    .dropdown-menu {
        display: none;
        position: relative;
        top: 0;
        width: 100%;
        padding-left: 1rem;
        overflow: hidden;
    }
}

/**/

#main-menu {
    @apply flex gap-8 flex-row items-center justify-end self-stretch
}

#main-menu a.menu-item {
    @apply text-nowrap whitespace-nowrap font-medium hover:text-neutral-600
}

#main-menu a.menu-item {
    @apply relative
}

#main-menu a.menu-item.active {
    @apply font-medium text-green-600
}

@media (max-width: 767px) {
    .overlay {
        position: fixed;
        z-index: 10;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0);
        visibility: hidden;
        transition: background-color 0.3s ease-in-out, visibility 0.3s ease-in-out;
    }
    .overlay.overlay--active {
        visibility: visible;
        background-color: rgba(0, 0, 0, 0.50);
    }

    .overflow {
        overflow: hidden;
    }
}
