*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.bottom-full {
  bottom: 100%;
}

.left-0 {
  left: 0;
}

.start-0 {
  inset-inline-start: 0;
}

.top-0 {
  top: 0;
}

.top-full {
  top: 100%;
}

.z-\[1\] {
  z-index: 1;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.size-4 {
  width: 1rem;
  height: 1rem;
}

.h-full {
  height: 100%;
}

.w-\[calc\(100\%-2rem\)\] {
  width: calc(100% - 2rem);
}

.w-full {
  width: 100%;
}

.\!max-w-4xl {
  max-width: 56rem !important;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.flex-none {
  flex: none;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-8 {
  gap: 2rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-slate-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 6 23 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[height\] {
  transition-property: height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

:root {
  --header-height: 80px;
  --header-min-width: 740px;
  --content-radius: 22px;
}

::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.border-radius {
  border-radius: var(--content-radius);
}

header + .container {
  margin-top: var(--header-height);
}

div.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

h1.page-title + div.container {
  margin-top: -4rem;
}

h1.page-title + .container {
  padding-top: 2rem;
}

.page-title {
  letter-spacing: -.025em;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--header-height);
  max-width: 1280px;
  padding: 4rem 2rem;
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1;
}

div.feature-list {
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 1024px) {
  div.feature-list {
    max-width: 56rem;
  }
}

dl.feature-list {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2.5rem 2rem;
  max-width: 36rem;
  display: grid;
}

@media (width >= 1024px) {
  dl.feature-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 4rem;
    max-width: none;
  }
}

dl.feature-list > .item {
  padding-left: 4rem;
  position: relative;
}

dl.feature-list > .item > dt.title {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

dl.feature-list > .item > dd.desc {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  margin-top: .5rem;
  font-size: 1rem;
  line-height: 1.75rem;
}

dl.feature-list > .item > dt.title > .icon {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

dl.feature-list > .item > dt.title > .icon svg {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  width: 1.5rem;
  height: 1.5rem;
}

ol.checklist {
  --tw-border-opacity: 1;
  border-inline-start-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  position: relative;
}

ol.checklist > li {
  margin-bottom: 2.5rem;
  margin-inline-start: 1.5rem;
}

ol.checklist > li > .icon {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: .25rem;
  display: flex;
  position: absolute;
  inset-inline-start: -.625rem;
}

ol.checklist > li > h3.title {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  margin-bottom: .375rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

ol.checklist > li > p.desc {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

div.banner {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (width >= 640px) {
  div.banner {
    padding: 6rem 1.5rem;
  }
}

@media (width >= 1024px) {
  div.banner {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

div.banner-container {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: #334155 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0;
  padding: 4rem;
}

@media (width >= 640px) {
  div.banner-container {
    border-radius: 22px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (width >= 1024px) {
  div.banner-container {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

div.banner > h2, div.banner h2.banner-title {
  text-align: center;
  letter-spacing: -.025em;
  max-width: 42rem;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
}

div.banner > h2 + p.banner-subtitle, div.banner h2.banner-title + p.banner-subtitle {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  margin-top: -3.5rem;
  margin-bottom: 4rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
}

@media (width >= 640px) {
  div.banner > h2 + p.banner-subtitle, div.banner h2.banner-title + p.banner-subtitle {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

div.banner > h2 + p.banner-desc, div.banner h2.banner-title + p.banner-desc {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  margin-top: -2rem;
  margin-bottom: 4rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
}

@media (width >= 640px) {
  div.banner > h2 + p.banner-desc, div.banner h2.banner-title + p.banner-desc {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

div.section {
  padding: 4rem 2rem;
}

div.section.condensed {
  padding: 2rem;
}

@media (width >= 1024px) {
  div.section.wide {
    padding-left: 0;
    padding-right: 0;
  }
}

div.section > h2, div.section h2.section-title {
  text-align: center;
  letter-spacing: -.025em;
  max-width: 42rem;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
}

div.section > h2 + p.section-subtitle, div.section h2.section-title + p.section-subtitle {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  margin-top: -3.5rem;
  margin-bottom: 4rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
}

@media (width >= 640px) {
  div.section > h2 + p.section-subtitle, div.section h2.section-title + p.section-subtitle {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

div.section > h2 + p.section-desc, div.section h2.section-title + p.section-desc {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  margin-top: -2rem;
  margin-bottom: 4rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
}

@media (width >= 640px) {
  div.section > h2 + p.section-desc, div.section h2.section-title + p.section-desc {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

div.section > h2 + div.content, div.section h2.section-title + div.content, div.section > p.section-subtitle + div.content, div.section > p.section-desc + div.content {
  padding-top: 2rem;
}

div.section > div.content {
  margin-left: auto;
  margin-right: auto;
}

.visibility-visible {
  visibility: visible;
}

.visibility-hidden {
  visibility: hidden;
}

a {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
  text-underline-offset: 4px;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

a:hover, a.rounded, a.rounded:hover {
  text-decoration-line: none;
}

button, a.btn {
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  border-radius: .375rem;
  margin: .25rem .25rem .25rem 0;
  padding: .625rem 1.25rem;
  font-weight: 500;
  text-decoration-line: none;
  display: inline-block;
}

button:hover, a.btn:hover {
  text-decoration-line: none;
}

button.primary, a.btn.primary {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

button.primary:hover, a.btn.primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

button.primary:focus, a.btn.primary:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(220 252 231 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

button.primary.arrow-link, a.btn.primary.arrow-link:after, button.black.arrow-link, a.btn.black.arrow-link:after {
  background-image: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 14'><path fill='%23fff' stroke='none' d='M8.136 0L15 7l-6.864 7-1.38-1.37L11.3 8.063H0V6.112l11.475-.001-4.718-4.74L8.136 0z' /></svg>");
}

button.black, a.btn.black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 229 229 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  border-width: 2px;
  border-color: #0000;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

button.black:hover, a.btn.black:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

button.black:focus-visible, a.btn.black:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

button.black, a.btn.white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(38 38 38 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  border-width: 2px;
  border-color: #0000;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

button.black:hover, a.btn.white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

button.black:focus-visible, a.btn.white:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

button.stroked, a.btn.stroked {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

button.stroked:hover, a.btn.stroked:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

button.stroked:focus-visible, a.btn.stroked:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

button.circle, a.btn.circle {
  border-radius: 9999px;
}

a.arrow-link {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

a.arrow-link:after {
  content: "";
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 14'><path fill='currentColor' stroke='none' d='M8.136 0L15 7l-6.864 7-1.38-1.37L11.3 8.063H0V6.112l11.475-.001-4.718-4.74L8.136 0z' /></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
  margin-left: 8px;
  margin-right: -20px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

a.arrow-link.ext:after {
  transform: rotate(-45deg);
}

a.arrow-link:hover:after {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

a.btn.arrow-link {
  align-items: center;
  column-gap: .25rem;
  padding-right: 32px;
  display: inline-flex;
}

.scroll-margin-top {
  scroll-margin-top: var(--header-height);
}

.scroll-margin-top-sm {
  scroll-margin-top: calc(var(--header-height)  + 1rem);
}

.scroll-margin-top-md {
  scroll-margin-top: calc(var(--header-height)  + 2rem);
}

.scroll-margin-top-lg {
  scroll-margin-top: calc(var(--header-height)  + 3rem);
}

.header-margin-top {
  margin-top: var(--header-height);
}

#contact [role="alert"] {
  margin-bottom: 2rem;
}

#contact [role="alert"].error {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(254 202 202 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

#contact [role="alert"].error a {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

#contact [role="alert"].success {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(187 247 208 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

#contact [role="alert"].success a {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.faq .hs-accordion-group {
  --tw-border-opacity: 1;
  border-left-width: 1px;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
  margin-top: 2rem;
  padding-left: 1.5rem;
  padding-right: 0;
}

.faq .hs-accordion-group .hs-accordion {
  margin-left: -1.5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1.5rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.faq .hs-accordion-group .hs-accordion.active {
  padding-bottom: 1rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.faq .hs-accordion-group .hs-accordion .hs-accordion-toggle {
  text-align: start;
  border-radius: .5rem;
  justify-content: space-between;
  align-items: center;
  column-gap: .75rem;
  width: 100%;
  margin-right: 0;
  padding-bottom: .75rem;
  padding-left: 0;
  padding-right: 0;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
  display: inline-flex;
}

.faq .hs-accordion-group .hs-accordion .hs-accordion-toggle:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.faq .hs-accordion-group .hs-accordion .hs-accordion-toggle:disabled {
  pointer-events: none;
  opacity: .5;
}

.faq .hs-accordion-group .hs-accordion .hs-accordion-toggle svg {
  min-width: 24px;
  min-height: 24px;
}

.faq .hs-accordion-group .hs-accordion.active .hs-accordion-toggle {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.faq .hs-accordion-group .hs-accordion.active {
  --tw-border-opacity: 1;
  border-left-width: 2px;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.faq .hs-accordion-content {
  padding-right: 2.5rem;
}

.placeholder\:text-transparent::placeholder {
  color: #0000;
}

.autofill\:pb-2:autofill {
  padding-bottom: .5rem;
}

.autofill\:pt-6:autofill {
  padding-top: 1.5rem;
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.focus\:pb-2:focus {
  padding-bottom: .5rem;
}

.focus\:pt-6:focus {
  padding-top: 1.5rem;
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity));
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.peer:focus ~ .peer-focus\:-translate-y-2\.5 {
  --tw-translate-y: -.625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.peer:focus ~ .peer-focus\:text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.peer:disabled ~ .peer-disabled\:pointer-events-none {
  pointer-events: none;
}

.peer:disabled ~ .peer-disabled\:opacity-50 {
  opacity: .5;
}

.peer:not(:placeholder-shown) ~ .peer-\[\:not\(\:placeholder-shown\)\]\:-translate-y-2\.5 {
  --tw-translate-y: -.625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:not(:placeholder-shown) ~ .peer-\[\:not\(\:placeholder-shown\)\]\:text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.peer:not(:placeholder-shown) ~ .peer-\[\:not\(\:placeholder-shown\)\]\:text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.hs-accordion.active.hs-accordion-active\:block {
  display: block;
}

.hs-accordion.active.hs-accordion-active\:hidden {
  display: none;
}

.hs-accordion.active.hs-accordion-active\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hs-accordion.active > .hs-accordion-toggle .hs-accordion-active\:block {
  display: block;
}

.hs-accordion.active > .hs-accordion-toggle .hs-accordion-active\:hidden {
  display: none;
}

.hs-accordion.active > .hs-accordion-toggle .hs-accordion-active\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hs-accordion.active > .hs-accordion-toggle.hs-accordion-active\:block {
  display: block;
}

.hs-accordion.active > .hs-accordion-toggle.hs-accordion-active\:hidden {
  display: none;
}

.hs-accordion.active > .hs-accordion-toggle.hs-accordion-active\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

@media (width >= 768px) {
  .md\:w-min {
    width: min-content;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (width >= 1280px) {
  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }

  .dark\:bg-slate-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  }

  .dark\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
}

.\[\&\:not\(\:placeholder-shown\)\]\:pb-2:not(:placeholder-shown) {
  padding-bottom: .5rem;
}

.\[\&\:not\(\:placeholder-shown\)\]\:pt-6:not(:placeholder-shown) {
  padding-top: 1.5rem;
}

header {
  min-height: var(--header-height);
  z-index: 50;
  -webkit-user-select: none;
  user-select: none;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  justify-content: space-between;
  width: 100%;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
  position: fixed;
  top: 0;
}

header a {
  color: inherit;
  text-decoration-line: none;
}

header a:hover {
  text-decoration-line: none;
}

header.scrolled {
  --tw-shadow: 0 8px 24px -12px #0003;
  --tw-shadow-colored: 0 8px 24px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

header nav {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 85rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

header ul.dropdown-menu {
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

header ul.dropdown-menu > li {
  display: flex;
}

header ul.dropdown-menu > li + li {
  margin-top: 0;
}

header ul.dropdown-menu > li > a {
  white-space: nowrap;
  text-wrap: nowrap;
  color: inherit;
  border-radius: .375rem;
  width: 100%;
  padding: 1rem 2rem 1rem 1rem;
}

header ul.dropdown-menu > li > a:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}

.menu-btn {
  content: "";
  cursor: pointer;
  background: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='currentColor' stroke='none'><path d='M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z'/></svg>") no-repeat;
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
}

@media (width >= 768px) {
  .menu-btn {
    display: none;
  }
}

.menu-btn.open {
  background: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='currentColor' stroke='none'><path d='m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z'/></svg>") no-repeat;
}

.expand-btn:after {
  content: "";
  opacity: .75;
  background: url("data:image/svg+xml; utf8, <svg class='ms-1 flex-shrink-0 size-4' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='m6 9 6 6 6-6'></path></svg>") no-repeat;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  transition: all .25s;
  display: inline-block;
  transform: rotate(0);
}

.expand-btn.open:after {
  transform: rotate(180deg);
}

.menu-items {
  align-self: stretch;
  align-items: center;
  display: flex;
}

.menu-items::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

.menu-items::-webkit-scrollbar {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  width: 6px;
}

.menu-items::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

@media (width <= 767px) {
  .menu-items {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    scrollbar-gutter: stable;
    left: -100vh;
    top: var(--header-height);
    width: 20rem;
    height: calc(100vh - 80px);
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    transition: left .3s ease-out;
    display: block;
    position: fixed;
    overflow-y: auto;
  }

  .menu-items.open {
    left: 0;
  }
}

.menu-items > li {
  display: block;
}

@media (width >= 640px) {
  .menu-items > li {
    align-self: stretch;
    align-items: center;
    display: flex;
  }
}

.menu-items > li > a {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width >= 640px) {
  .menu-items > li > a {
    align-self: stretch;
  }
}

.menu-items > li > .menu-item {
  padding-left: .75rem;
  padding-right: .75rem;
  display: flex;
}

@media (width <= 767px) {
  .menu-items > li > .menu-item {
    padding: 1rem;
  }

  .menu-items li a {
    padding: 10px 1rem;
  }
}

.dropdown {
  position: relative;
}

@media (width >= 768px) {
  .dropdown:hover > .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }

  .dropdown:hover > .expand-btn:after {
    transform: rotate(180deg);
  }

  .dropdown .dropdown-right .expand-btn:after, .dropdown .dropdown-left .expand-btn:after {
    right: .625rem;
  }
}

.dropdown-menu {
  white-space: nowrap;
  text-wrap: nowrap;
  width: fit-content;
}

@media (width >= 768px) {
  .dropdown-menu {
    opacity: 0;
    visibility: hidden;
    top: var(--header-height);
    z-index: 10;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-shadow: 0 24px 24px 0 #0003;
    --tw-shadow-colored: 0 24px 24px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transition: all .4s;
    position: absolute;
    right: 0;
  }
}

@media (width <= 767px) {
  .dropdown-menu {
    width: 100%;
    padding-left: 1rem;
    display: none;
    position: relative;
    top: 0;
    overflow: hidden;
  }
}

#main-menu {
  flex-direction: row;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 2rem;
  display: flex;
}

#main-menu a.menu-item {
  white-space: nowrap;
  text-wrap: nowrap;
  font-weight: 500;
}

#main-menu a.menu-item:hover {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

#main-menu a.menu-item {
  position: relative;
}

#main-menu a.menu-item.active {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
  font-weight: 500;
}

@media (width <= 767px) {
  .overlay {
    z-index: 10;
    visibility: hidden;
    background-color: #0000;
    transition: background-color .3s ease-in-out, visibility .3s ease-in-out;
    position: fixed;
    inset: 0;
  }

  .overlay.overlay--active {
    visibility: visible;
    background-color: #00000080;
  }

  .overflow {
    overflow: hidden;
  }
}

#footer {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: #020617 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  margin-top: 4rem;
}

#footer a {
  color: inherit;
  text-decoration-line: none;
}

#footer a:hover {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

#footer .title {
  letter-spacing: .025em;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

@media (width >= 768px) {
  #footer .title {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1280px) {
  #footer .title {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

#footer p {
  line-height: 2;
}

header + div {
  margin-top: var(--header-height);
}

.container.first {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (width >= 1024px) {
  .container.first {
    flex-direction: row;
  }
}

.container.first {
  margin-top: var(--header-height);
  min-height: calc(100vh - var(--header-height));
}

a {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

a:hover {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.cards .card {
  border-radius: var(--content-radius);
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
  --tw-shadow: 2px 2px 16px 0px #00000008;
  --tw-shadow-colored: 2px 2px 16px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  max-width: 56rem;
  padding: 3rem;
}

.cards .card + .card {
  margin-top: 2rem;
}

.cards .card .title {
  letter-spacing: -.025em;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.25rem;
}

.cards .card .desc {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
  margin-top: .5rem;
}

.cards .card .actions {
  margin-top: 2rem;
}
/*# sourceMappingURL=index.aeedd1fa.css.map */
