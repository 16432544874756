@import "base.css";
@import "header.css";
@import "footer.css";


header + div {
    margin-top: var(--header-height);
}

.container.first {
    @apply flex items-center flex-col lg:flex-row;
    margin-top: var(--header-height);
    min-height: calc(100vh - var(--header-height));
}

a {
    @apply text-black hover:text-green-600
}

.cards {

}

.cards .card {
    border-radius: var(--content-radius);
    @apply max-w-4xl p-12 bg-neutral-50
    shadow-[2px_2px_16px_0px_rgba(0,0,0,0.03)]
    /* border border-neutral-200 */
}

.cards .card + .card {
    @apply mt-8
}

.cards .card .title {
    @apply text-3xl font-semibold tracking-tight
}

.cards .card .desc {
    @apply text-neutral-600 mt-2
}

.cards .card .actions {
    @apply mt-8
}