@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */

:root {
    --header-height: 80px;
    --header-min-width: 740px;
    --content-radius: 22px;
}

::selection {
    @apply bg-neutral-200 text-neutral-800
}

.border-radius {
    border-radius: var(--content-radius);
}

header + .container {
    margin-top: var(--header-height);
}

div.container {
    @apply max-w-screen-xl mx-auto
}

h1.page-title + div.container {
    @apply -mt-16
}

h1.page-title + .container {
    @apply pt-8
}

.page-title {
    @apply max-w-screen-xl mx-auto px-8 pt-16 pb-16 text-6xl font-bold tracking-tight;
    margin-top: var(--header-height);
}

div.feature-list {
    @apply max-w-2xl mx-auto lg:max-w-4xl
}

dl.feature-list {
    @apply grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16
}

dl.feature-list > .item {
    @apply relative pl-16
}

dl.feature-list >.item > dt.title {
    @apply text-lg font-semibold leading-7 text-gray-900
}

dl.feature-list > .item > dd.desc {
    @apply mt-2 text-base leading-7 text-gray-600
}

dl.feature-list > .item > dt.title > .icon {
    @apply absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-green-600
}

dl.feature-list > .item > dt.title > .icon svg {
    @apply w-6 h-6 text-white
}

/* Checklist */

ol.checklist {
    @apply relative border-s border-gray-200
}

ol.checklist > li {
    @apply mb-10 ms-6
}

ol.checklist > li > .icon {
    @apply absolute -start-2.5 flex mt-1 h-5 w-5 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white
}

ol.checklist > li > h3.title {
    @apply text-lg mb-1.5 font-semibold leading-7 text-gray-900 /*leading-none*/
}

ol.checklist > li > p.desc {
    @apply text-base font-normal text-gray-500
}

div.banner {
    @apply py-16 sm:px-6 sm:py-24 lg:px-8
}

div.banner-container {
    @apply bg-gradient-to-b from-slate-700 to-slate-900 shadow-2xl sm:px-16 px-16 py-16 lg:py-24 rounded-none sm:rounded-[22px] /* TODO: Take from var(--content-radius) */
}

div.banner > h2, div.banner h2.banner-title {
    @apply text-center mx-auto max-w-2xl text-4xl font-bold tracking-tight mb-16
}

div.banner > h2 + p.banner-subtitle, div.banner h2.banner-title + p.banner-subtitle {
    @apply font-light text-lg sm:text-xl leading-8 text-center -mt-14 mb-16 text-gray-400
}

div.banner > h2 + p.banner-desc, div.banner h2.banner-title + p.banner-desc {
    @apply font-light text-lg sm:text-xl leading-8 text-center -mt-8 mb-16 text-gray-400
}

div.section {
    @apply px-8 py-16
}

div.section.condensed {
    @apply px-8 pt-8 pb-8
}

div.section.wide {
    @apply lg:px-0
}

div.section > h2, div.section h2.section-title {
    @apply text-center mx-auto max-w-2xl text-4xl font-bold tracking-tight mb-16
}

div.section > h2 + p.section-subtitle, div.section h2.section-title + p.section-subtitle {
    @apply font-light text-lg sm:text-xl leading-8 text-center -mt-14 mb-16 text-gray-600
}

div.section > h2 + p.section-desc, div.section h2.section-title + p.section-desc {
    @apply font-light text-lg sm:text-xl leading-8 text-center -mt-8 mb-16 text-gray-600
}

div.section > h2 + div.content,
div.section h2.section-title + div.content,
div.section > p.section-subtitle + div.content,
div.section > p.section-desc + div.content {
    @apply pt-8
}

div.section > div.content {
    @apply mx-auto
}

.visibility-visible {
    visibility: visible;
}

.visibility-hidden {
    visibility: hidden;
}

a {
    @apply text-green-700 underline decoration-1 underline-offset-4 hover:no-underline
}

a.rounded {
    @apply no-underline hover:no-underline
}

/* Buttons */
button, a.btn {
    @apply inline-block rounded-md select-none text-center py-2.5 px-5 font-medium no-underline hover:no-underline m-1 ml-0
}

button.primary, a.btn.primary {
    @apply bg-green-600 text-white hover:bg-green-700 transition ease-in-out duration-200 focus:outline-none focus:ring-4 focus:ring-green-100
}

button.primary.arrow-link, a.btn.primary.arrow-link:after, button.black.arrow-link, a.btn.black.arrow-link:after {
    background-image: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 14'><path fill='%23fff' stroke='none' d='M8.136 0L15 7l-6.864 7-1.38-1.37L11.3 8.063H0V6.112l11.475-.001-4.718-4.74L8.136 0z' /></svg>");
}

button.black, a.btn.black {
    @apply transition focus-visible:ring-2 ring-offset-2 ring-neutral-200 bg-black text-neutral-100 hover:bg-neutral-800 border-2 border-transparent inline-flex gap-1 items-center justify-center
}

button.black, a.btn.white {
    /* text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white */
    @apply transition focus-visible:ring-2 ring-offset-2 ring-neutral-800 bg-white text-neutral-800 hover:bg-neutral-100 border-2 border-transparent flex gap-1 items-center justify-center
}

button.stroked, a.btn.stroked {
    @apply transition focus-visible:ring-2 ring-offset-2 ring-gray-200 border-2 border-black hover:bg-gray-100 text-black inline-flex gap-1 items-center justify-center
}

button.circle, a.btn.circle {
    @apply rounded-full
}

a.arrow-link {
    display: inline-block;
    @apply transition ease-in-out duration-200
}

/* https://stackoverflow.com/questions/16100956/prevent-after-element-from-wrapping-to-next-line, https://jsfiddle.net/Bk38F/65/ */
a.arrow-link:after {
    display: inline-block;
    content: '';
    background-image: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 14'><path fill='currentColor' stroke='none' d='M8.136 0L15 7l-6.864 7-1.38-1.37L11.3 8.063H0V6.112l11.475-.001-4.718-4.74L8.136 0z' /></svg>");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    margin-right: -20px;
    margin-left: 8px;
    @apply transition ease-in-out translate-x-0 duration-200;
}

/* style='fill:rgb(226,232,240); */

a.arrow-link.ext:after {
    transform: rotate(-45deg);
}

a.arrow-link:hover:after {
    @apply transition ease-in-out translate-x-1 duration-200;
}

a.btn.arrow-link {
    padding-right: 32px;
    @apply inline-flex items-center gap-x-1
}

.scroll-margin-top {
    scroll-margin-top: var(--header-height);
}

.scroll-margin-top-sm {
    scroll-margin-top: calc(var(--header-height) + 1rem);
}

.scroll-margin-top-md {
    scroll-margin-top: calc(var(--header-height) + 2rem);
}

.scroll-margin-top-lg {
    scroll-margin-top: calc(var(--header-height) + 3rem);
}

.header-margin-top {
    margin-top: var(--header-height);
}

#contact [role=alert] {
    @apply mb-8
}

#contact [role=alert].error {
    @apply bg-red-100 border border-red-200 text-sm text-red-800 rounded-lg p-4
}

#contact [role=alert].error a {
    @apply text-red-800
}

#contact [role=alert].success {
    @apply bg-green-100 border border-green-200 text-sm text-green-800 rounded-lg p-4
}

#contact [role=alert].success a {
    @apply text-green-800
}

.faq {
}

.faq .hs-accordion-group {
    @apply border-l border-neutral-200 mt-8 px-6 pr-0
}

.faq .hs-accordion-group .hs-accordion {
    @apply transition-all -ml-6 pl-6 py-2
}

.faq .hs-accordion-group .hs-accordion.active {
    @apply transition-all pb-4
}

.faq .hs-accordion-group .hs-accordion .hs-accordion-toggle {
    @apply pb-3 px-0 mr-0 text-lg inline-flex items-center justify-between gap-x-3 w-full font-semibold text-start hover:text-gray-600 rounded-lg disabled:opacity-50 disabled:pointer-events-none
}

.faq .hs-accordion-group .hs-accordion .hs-accordion-toggle svg {
    min-width: 24px;
    min-height: 24px;
}

.faq .hs-accordion-group .hs-accordion.active .hs-accordion-toggle {
    @apply text-green-600
}

.faq .hs-accordion-group .hs-accordion.active {
    @apply border-l border-l-2 border-green-500
}

.faq .hs-accordion-content {
    padding-right: 2.5rem;
}